<template>
  <div>
    <b-row>
      <table class="table table-sm ">
        <thead>
          <tr>
            <th scope="col" class="table-primary">Price Calculation</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="12" xl="6">
        <table class="table border table-sm">
          <tr>
            <th scope="col" class="text-right">Vehicle Leased Price</th>
            <td scope="col" v-if="leaseQuoteData.leasedPrice != null">{{ formatPrice(leaseQuoteData.leasedPrice) }}</td>
            <td scope="col" v-else>{{ formatPrice(0) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Cash Down <span class="text-danger"> </span></td>
            <td>
              <cleave style="height:2.142rem" v-model="leaseQuoteData.cashDown" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Trade as Cash Down</th>
            <td scope="col">
              {{ formatPrice(0, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Net Cap</th>
            <td scope="col">
              {{ formatPrice(netCap, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Residual <span class="text-danger"> </span></td>
            <td>
              <cleave style="height:2.142rem" v-model="leaseQuoteData.residual" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Amount Amortized</th>
            <td scope="col">
              {{ formatPrice(amortized, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Security Deposit</td>

            <td><cleave id="options" style="height:2.142rem" v-model="leaseQuoteData.securityDeposit" class="form-control" :raw="true" :options="options.number" /></td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" style="width: 50%;" class="text-right">G.S.T Rate & Amount on Vehicle</td>
            <td style="width: 30%;"><cleave id="options" style="height:2.142rem; " v-model="leaseQuoteData.gstRateVehicle" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" style="width: 40%;" class="text-dark font-weight-bold">{{ formatPrice(gstAmountOnVehicle, 2) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">P.S.T Rate & Amount on Vehicle</td>
            <td><cleave id="options" style="height:2.142rem" v-model="leaseQuoteData.pstRateVehicle" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" class="text-dark font-weight-bold">{{ formatPrice(pstAmountOnVehicle, 2) }}</td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" sm="12" md="12" xl="6">
        <table class="table border table-sm">
          <tr>
            <td scope="col" style="width: 50%;" class="text-right font-weight-bold">Due On Delivery</td>
            <td scope="col" style="width: 30%;">{{ formatPrice(dueDelivery, 2) }}</td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right font-weight-bold">Term</td>
            <td><cleave id="options" style="height:2.142rem;" v-model="leaseQuoteData.term" class="form-control" :raw="true" :options="options.number" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Rate</td>
            <td><cleave id="options" style="height:2.142rem" v-model="leaseQuoteData.rate" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Base Payment</th>
            <td scope="col">
              {{ formatPrice(basePayment, 2) }}
            </td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Monthly Admin</td>

            <td><cleave id="options" style="height:2.142rem" v-model="leaseQuoteData.monthlyAdmin" class="form-control" :raw="true" :options="options.number" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Net Payment Before Tax</th>
            <td scope="col">
              {{ formatPrice(netPayment, 2) }}
            </td>
            <td scope="col" style="width: 20%;"></td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BButton } from 'bootstrap-vue';
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,

    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    leaseQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      discountRateVal: 0,
      userData: JSON.parse(localStorage.getItem('userData')),

      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'leaseQuoteData.listingPrice': {
      handler: function(val, before) {
        if (this.leaseQuoteData.listingPrice != 0) {
        } else {
        }
      },
    },

    'leaseQuoteData.deductionCredit': {
      handler: function(val, before) {
        var tempCustomerTrade = Number(this.leaseQuoteData.creditIssued) - Number(this.leaseQuoteData.leasedPrice) - Number(this.leaseQuoteData.lessAdditionalCredit);

        if (tempCustomerTrade > 0) {
          if (Number(this.leaseQuoteData.deductionCredit) > Number(tempCustomerTrade)) {
            this.leaseQuoteData.deductionCredit = Number(tempCustomerTrade);
          }

          if (Number(this.leaseQuoteData.deductionCredit) > Number(this.additionaloptionsPrice)) {
            this.leaseQuoteData.deductionCredit = Number(this.additionaloptionsPrice);
          }
        }
      },
    },

    'leaseQuoteData.creditIssued': {
      handler: function(val, before) {
        if (val <= 0) {
          this.leaseQuoteData.deductionCredit = null;
          this.leaseQuoteData.lessAdditionalCredit = null;
        }
      },
    },

    'leaseQuoteData.lessAdditionalCredit': {
      handler: function(val, before) {
        // this.leaseQuoteData.balanceAfter = 0;   //ORHAN 1

        var tempCustomerTrade = Number(this.leaseQuoteData.creditIssued) - Number(this.leaseQuoteData.leasedPrice) - Number(this.leaseQuoteData.deductionCredit);

        var total = 0;

        if (this.sumBeforeTax > 0) {
          //VERGİ VARSA

          total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.leaseQuoteData.lessDepositPayable) + Number(this.totalVehicleSaleAmount));
        } else {
          //VERGİ YOKSA

          if (this.leaseQuoteData.creditIssued < this.leaseQuoteData.leasedPrice) {
            total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions);
          } else {
            total = Number(Number(this.leaseQuoteData.leasedPrice) - Number(this.leaseQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.leaseQuoteData.deductionCredit));
          }
        }

        if (tempCustomerTrade > 0) {
          if (Number(this.leaseQuoteData.lessAdditionalCredit) > total) {
            this.leaseQuoteData.lessAdditionalCredit = total;
          }
        }
      },
    },

    'leaseQuoteData.leasedPrice': {
      handler: function(val, before) {
        // this.leaseQuoteData.balanceAfter = 0; // ORHAN 2

        if (this.leaseQuoteData.listingPrice != 0 && this.leaseQuoteData.listingPrice != null) {
          if (val > this.leaseQuoteData.listingPrice) {
            this.leaseQuoteData.leasedPrice = this.leaseQuoteData.listingPrice;
          }
          this.discountRateVal = this.leaseQuoteData.listingPrice - this.leaseQuoteData.leasedPrice;
        }
      },
    },

    'leaseQuoteData.lessDepositPayable': {
      //505
      handler: function(val, before) {
        var total = 0;

        if (this.sumBeforeTax > 0) {
          //VERGİ VARSA

          total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.leaseQuoteData.lessAdditionalCredit) + Number(this.totalVehicleSaleAmount));
        } else {
          //VERGİ YOKSA

          if (this.leaseQuoteData.creditIssued < this.leaseQuoteData.leasedPrice) {
            total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions);
          } else {
            total = Number(Number(this.leaseQuoteData.leasedPrice) - Number(this.leaseQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.leaseQuoteData.deductionCredit));
          }
        }

        if (this.leaseQuoteData.lessDepositPayable != null || this.leaseQuoteData.lessDepositPayable != '') {
          if (total - Number(this.leaseQuoteData.lessDepositPayable) < 0) {
            this.leaseQuoteData.lessDepositPayable = total;
          }
        }
      },
    },

    'leaseQuoteData.discountRate': {
      handler: function(val, before) {
        if (this.userData.discountLimit != null && this.userData.discountLimit != '') {
          if (val > this.userData.discountLimit) {
            this.leaseQuoteData.discountRate = 10;
          }
        }

        if (this.leaseQuoteData.discountRate == null || this.leaseQuoteData.discountRate == '') {
          this.discountRateVal = 0;
        } else {
          this.discountRateVal = this.leaseQuoteData.listingPrice * (this.leaseQuoteData.discountRate / 100);
        }
      },
    },

    'priceCalculationTemp.pcPrice': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPrice != null || this.priceCalculationTemp.pcGst != '' || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcGstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst;

          this.priceCalculationTemp.pcPstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst;

          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcGst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcGst != null || this.priceCalculationTemp.pcGst != '') {
          this.priceCalculationTemp.pcGstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcGst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcPst': {
      handler: function(id, before) {
        if (this.priceCalculationTemp.pcPst != null || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcPstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcPst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },
  },

  computed: {
    netCap() {
      this.leaseQuoteData.netCap = this.leaseQuoteData.leasedPrice - this.leaseQuoteData.cashDown;
      return Number(this.leaseQuoteData.netCap ?? 0);
    },

    amortized() {
      this.leaseQuoteData.amortized = this.leaseQuoteData.netCap - this.leaseQuoteData.residual;
      return Number(this.leaseQuoteData.amortized ?? 0);
    },

    dueDelivery() {
      this.leaseQuoteData.dueDelivery = Number(this.leaseQuoteData.cashDown) + Number(this.leaseQuoteData.securityDeposit) + Number(this.leaseQuoteData.gstAmountVehicle) + Number(this.leaseQuoteData.pstAmountVehicle);
      return Number(this.leaseQuoteData.dueDelivery ?? 0);
    },
    basePayment() {
      let r = Number(this.leaseQuoteData.rate) / 100 / 12;
      let pv = Number(this.leaseQuoteData.netCap) * -1;
      let n = Number(this.leaseQuoteData.term == 0);
      n = n == 0 ? 1 : n;
      let fv = Number(this.leaseQuoteData.residual);
      this.leaseQuoteData.basePayment = this.PMT(r, n, pv, fv, 1);
      return Number(this.leaseQuoteData.basePayment ? this.leaseQuoteData.basePayment : 0);
    },

    netPayment() {
      this.leaseQuoteData.netPayment = Number(this.leaseQuoteData.basePayment) + Number(this.leaseQuoteData.monthlyAdmin);
      return Number(this.leaseQuoteData.netPayment ? this.leaseQuoteData.netPayment : 0);
    },

    priceDifference() {
      var tempCustomerTrade = Number(this.leaseQuoteData.creditIssued - this.leaseQuoteData.leasedPrice - this.leaseQuoteData.deductionCredit);
      if (tempCustomerTrade <= 0) {
        return Number((this.leaseQuoteData.leasedPrice ? this.leaseQuoteData.leasedPrice : 0) - (this.leaseQuoteData.creditIssued ? this.leaseQuoteData.creditIssued : 0));
      } else {
        return 0;
      }
    },

    sumBeforeTax() {
      // this.leaseQuoteData.lessDepositPayable = 0;
      // this.leaseQuoteData.lessAdditionalCredit = 0;

      var total = 0;

      this.leaseQuoteData.trades.forEach((element) => {
        total += Number(element.beforeTax);
      });

      this.leaseQuoteData.winTrades.forEach((element) => {
        total += Number(element.beforeTax);
      });

      return Number(total);
    },

    saleBalanceDue() {
      var total = 0;
      if (this.sumBeforeTax > 0) {
        //VERGİ VARSA

        total = Number(Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.leaseQuoteData.lessAdditionalCredit) - Number(this.leaseQuoteData.lessDepositPayable) + Number(this.totalVehicleSaleAmount));
      } else {
        //VERGİ YOKSA

        if (this.leaseQuoteData.creditIssued < this.leaseQuoteData.leasedPrice) {
          total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions - Number(this.leaseQuoteData.lessDepositPayable));
        } else {
          total = Number(Number(this.leaseQuoteData.leasedPrice) - Number(this.leaseQuoteData.creditIssued) + Number(this.totalAdditionalOptions) + Number(this.leaseQuoteData.deductionCredit));
        }
      }

      this.leaseQuoteData.balanceDue = total;

      return total;
    },

    // customerTradeEquity() {
    //   if (this.leaseQuoteData.creditIssued > this.leaseQuoteData.leasedPrice) {
    //     return this.leaseQuoteData.creditIssued - this.leaseQuoteData.leasedPrice - this.leaseQuoteData.deductionCredit - this.leaseQuoteData.lessAdditionalCredit;
    //   } else {
    //     this.leaseQuoteData.deductionCredit = null;
    //     this.leaseQuoteData.lessAdditionalCredit = null;
    //     return 0;
    //   }
    // },

    gstAmountOnVehicle() {
      this.leaseQuoteData.gstAmountVehicle = (this.leaseQuoteData.cashDown + 0) * (this.leaseQuoteData.gstRateVehicle / 1000);
      return this.leaseQuoteData.gstAmountVehicle;
    },

    pstAmountOnVehicle() {
      this.leaseQuoteData.pstAmountVehicle = (this.leaseQuoteData.cashDown + 0) * (this.leaseQuoteData.pstRateVehicle / 1000);
      return this.leaseQuoteData.pstAmountVehicle;
    },

    totalVehicleSaleAmount() {
      return Number(this.priceDifference + this.gstAmountOnVehicle + this.pstAmountOnVehicle);
    },

    totalAdditionalOptions() {
      return Number(this.additionaloptionsPrice - (this.leaseQuoteData.deductionCredit ? this.leaseQuoteData.deductionCredit : 0) + this.gstAdditionalOptions + this.pstAdditionalOptions);
    },

    additionaloptionsPrice() {
      var total = 0;
      this.leaseQuoteData.deductionCredit = 0;
      this.leaseQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcPrice);
      });

      return total;
    },

    gstAdditionalOptions() {
      if (this.leaseQuoteData.creditIssued > this.leaseQuoteData.leasedPrice) {
        var rate = 100;
        var total = 0;

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcGst) < Number(rate)) {
            rate = element.pcGst;
          }
        });

        total = ((this.additionaloptionsPrice - this.leaseQuoteData.deductionCredit) * rate) / 100;

        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcGst) < Number(rate)) {
            rate = element.pcGst;
          }
        });

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });

        return total;
      }
    },

    pstAdditionalOptions() {
      if (this.leaseQuoteData.creditIssued > this.leaseQuoteData.leasedPrice) {
        var rate = 100;
        var total = 0;

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcPst) < Number(rate)) {
            rate = element.pcPst;
          }
        });

        total = ((this.additionaloptionsPrice - this.leaseQuoteData.deductionCredit) * rate) / 100;

        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          if (Number(element.pcPst) < Number(rate)) {
            rate = element.pcPst;
          }
        });

        this.leaseQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });

        return total;
      }
    },

    contractAmount() {
      return (
        Number(this.additionaloptionsPrice) +
        Number(this.priceDifference) +
        Number(this.gstAmountOnVehicle) +
        Number(this.pstAmountOnVehicle) +
        Number(this.sumBeforeTax) -
        (this.leaseQuoteData.deductionCredit ? this.leaseQuoteData.deductionCredit : 0) +
        Number(this.gstAdditionalOptions) +
        Number(this.pstAdditionalOptions)
      );
    },
  },

  methods: {
    PMT(ir, np, pv, fv, type) {
      /*
       * ir   - interest rate per month
       * np   - number of periods (months)
       * pv   - present value
       * fv   - future value
       * type - when the payments are due:
       *        0: end of the period, e.g. end of month (default)
       *        1: beginning of period
       */
      var pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt;
    },

    priceCalculation() {
      this.leaseQuoteData.priceCalculationValues.push(this.priceCalculationTemp);
      this.priceCalculationTemp = {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      };
    },

    rateCalc() {
      this.leaseQuoteData.leasedPrice = this.leaseQuoteData.listingPrice - (this.leaseQuoteData.listingPrice * this.leaseQuoteData.discountRate) / 100;
    },

    sellingPriceCalc() {
      this.leaseQuoteData.discountRate = 100 - (100 * this.leaseQuoteData.leasedPrice) / this.leaseQuoteData.listingPrice;
    },

    removeAdditionalItem(e) {
      this.$delete(this.leaseQuoteData.priceCalculationValues, e);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>

<style scoped>
tr {
  height: 3.5em;
}
</style>
