<template>
  <div>
    <b-overlay :show="formShow" rounded="sm" no-fade>
      <form-wizard color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" finish-button-text="Save" back-button-text="Previous" class="vertical-steps steps-transparent" @on-complete="formSubmitted">
        <tab-content title="Description of Vehicle" icon="feather icon-file-text">
          <description-of-vehicle :dropInventorys.sync="dropInventorys" :dropTradeQuotes.sync="dropTradeQuotes" :formShow.sync="formShow" :tradeData.sync="tradeData" :dropAll.sync="dropAll" ref="subDescriptionComponent"></description-of-vehicle>
        </tab-content>

        <!-- <tab-content title="MSRP Configuration" icon="feather icon-file-text">
          <msrp-configuration :tradeData.sync="tradeData" :msrpOptions.sync="msrpOptions" :dropAll.sync="dropAll"></msrp-configuration>
        </tab-content>
 
       <tab-content title="Accessories Registration" icon="feather icon-file-text">
          <accessories-registration :tradeData.sync="tradeData" :accessioreOption.sync="accessioreOption" :dropAll.sync="dropAll"></accessories-registration>
        </tab-content> 
        <tab-content title="Vehicle Declearation" icon="feather icon-file-text">
          <vehicle-declearation :tradeData.sync="tradeData"></vehicle-declearation>
        </tab-content> -->

        <tab-content title="Vehicle Appraisal Photos" icon="feather icon-file-text">
          <vehicle-gallery :tradeData.sync="tradeData"></vehicle-gallery>
        </tab-content>

        <tab-content title="Vehicle Appraisal & Lien" icon="feather icon-file-text">
          <vehicle-appraisal :tradeData.sync="tradeData" ref="subAppraisalComponent"></vehicle-appraisal>
        </tab-content>
        <tab-content title="Lien Holder Identification" icon="feather icon-file-text" v-if="tradeData.vehicleTitleType != 1">
          <lien-holder :tradeData.sync="tradeData" :dropCustomers.sync="dropCustomers" :formShow.sync="formShow" ref="subLienComponent"> </lien-holder>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { BContainer, BOverlay } from 'bootstrap-vue';
import purchaseStoreModule from '@/views/purchase/purchaseStoreModule';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';
import { required, email } from '@validations';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DescriptionOfVehicle from './DescriptionOfVehicle.vue';
// import MsrpConfiguration from './MsrpConfiguration.vue';
// import AccessoriesRegistration from './AccessoriesRegistration.vue';
// import VehicleDeclearation from './VehicleDeclearation.vue';
import VehicleAppraisal from './VehicleAppraisal.vue';
import VehicleGallery from './VehicleGallery.vue';
import LienHolder from './LienHolder.vue';
export default {
  components: {
    BContainer,
    BOverlay,
    FormWizard,
    TabContent,
    WizardButton,
    required,
    email,
    //TabPage
    DescriptionOfVehicle,
    // MsrpConfiguration,
    // AccessoriesRegistration,
    // VehicleDeclearation,
    VehicleAppraisal,
    VehicleGallery,
    LienHolder,
  },
  directives: {
    Ripple,
  },
  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'purchase';
    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },
  watch: {
    'tradeData.inventoryId': {
      handler: function(x, y) {
        if (y != undefined) {
          this.clearInventoryData();
        }
        if (x != undefined) {
          if (this.leaseQuoteData.inventoryId != x) {
            var countTrade = 0;
            this.leaseQuoteData.trades.forEach((item) => {
              if (item.inventoryId != x) {
              } else {
                countTrade++;
              }
            });
            if (countTrade > 0) {
              this.$swal({
                title: 'This vehicle cannot be selected',
                text: 'Vehicle selected to trade cannot be selected for trade',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                this.tradeData.inventoryId = null;
              });
            } else {
              this.getTradeInventorySelectId(x);
            }
          } else {
            this.$swal({
              title: 'This vehicle cannot be selected',
              text: 'Vehicle selected to sell cannot be selected for trade',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              this.tradeData.inventoryId = null;
            });
          }
        }
      },
      deep: true,
    },

    tradeDataChildData: {
      handler: function(x, y) {
        if (x != null) {
          this.getleaseQuoteData(x);
        }
      },
    },
  },
  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
  },
  created() {
    this.getAllDropDown();
    this.getInventoryTradeDrop();
    this.getCustomerDrops();
  },
  props: {
    leaseQuoteData: {
      type: Object,
      required: true,
    },
    hide: {
      required: false,
    },
    pageReload: {
      required: true,
    },
    tradeDataChildData: {
      required: false,
    },
  },
  data() {
    var tradeDataModel = {
      inventoryId: null,
      vehicleId: null,
      condition: null,
      mileageUnit: null,
      extColour: null,
      modelYear: null,
      mileageValue: null,
      intColour: null,
      make: null,
      body: null,
      fuel: null,
      model: null,
      seatingCapacity: null,
      drivetrain: null,
      trim: null,
      numberOfDoors: null,
      transmmission: null,
      stockNumber: null,
      vinNo: null,
      origenalMarket: null,
      msrpCurrency: null,
      baseMsrp: null,
      msrpOptions: [],
      accessioreOptions: [],
      vehicleDeclearation: [null, null, null, null, null, null, null],
      vehicleDeclearationText: null,
      vehicleTitleType: null,
      appraisedVehiclePrice: null,
      creditDue: null,
      appraisalNotes: null,
      payoutType: null,
      payoutTax: null,
      goodUnitlDate: null,
      payoutGstRate: null,
      payoutGstAmount: null,
      payoutTotalAmount: null,
      quoteGallery: [],
      deleteImages: [],
      lienCustomerId: null,
      lienHolder: {
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      },
      quoteNumber: null,
    };
    return {
      formShow: false,
      baseURL: store.state.app.baseURL,
      tradeData: tradeDataModel,
      dropAll: [],
      sellerCustomerData: {},
      dropInventorys: [],
      dropTradeQuotes: [],
      dropCustomers: [],
      msrpValueOption: null,
      msrpValueOptions: [],
      msrpPriceOption: null,
      msrpPriceOptions: [],
      accessioreValueOptions: [],
      accessioreQuantityOptions: null,
      msrpOptions: [],
      msrpOption: [],
      accessioreOption: [],
      dropdownLoading: false,
    };
  },
  methods: {
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    formSubmitted() {
      this.$refs.subDescriptionComponent.$refs.vehicleRules.validate().then((success) => {
        if (success) {
          //Appraisal
          this.$refs.subAppraisalComponent.$refs.appraisalRules.validate().then((success) => {
            if (success) {
              if (this.tradeData.vehicleTitleType == 2) {
                this.$refs.subLienComponent.$refs.lienRules.validate().then((success) => {
                  if (success) {
                    //finaly
                    if (this.tradeData.index != undefined) {
                      this.leaseQuoteData.trades.splice(this.tradeData.index, 1);
                      this.leaseQuoteData.trades.push(this.tradeData);
                    } else {
                      this.leaseQuoteData.trades.push(this.tradeData);
                    }
                    this.hide();
                    this.$emit('update:pageReload', false);
                    //ok
                  } else {
                    this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 3);
                  }
                });
              } else {
                //final no lien
                if (this.tradeData.index != undefined) {
                  this.leaseQuoteData.trades.splice(this.tradeData.index, 1);
                  this.leaseQuoteData.trades.push(this.tradeData);
                } else {
                  this.leaseQuoteData.trades.push(this.tradeData);
                }
                this.hide();
                this.$emit('update:pageReload', false);
                //ok
              }
            } else {
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 2);
            }
          });
        } else {
          this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
        }
      });
    },
    saveOrUpdateQuote() {
      this.formShow = true;
      if (router.currentRoute.params.id) {
        store.dispatch('purchase/updateQuote', this.tradeData).then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            setTimeout(function() {
              this.formShow = false;
              router.push({ name: 'quote-list' });
            }, 1000);
          }
        });
      } else {
        store.dispatch('purchase/saveQuote', this.tradeData).then((response) => {
          if (response.status == 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Purchase Quote Insert Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            setTimeout(function() {
              this.formShow = false;
              router.push({ name: 'quote-list' });
            }, 1000);
          }
        });
      }
    },
    getAllDropDown() {
      store
        .dispatch('purchase/getAllDropDowns')
        .then((response) => {
          this.dropAll = response.data;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching dropdowns list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    getleaseQuoteData(data) {
      this.tradeData.id = data.id;
      this.tradeData.index = data.index;
      this.tradeData.inventoryId = data.inventoryId;
      this.tradeData.vehicleId = data.vehicleId;
      this.tradeData.condition = data.condition.charAt(0).toUpperCase() + data.condition.slice(1);
      this.tradeData.mileageUnit = data.mileageUnit;
      this.tradeData.extColour = data.extColour;
      this.tradeData.modelYear = data.modelYear;
      this.tradeData.mileageValue = data.mileageValue;
      this.tradeData.intColour = data.intColour;
      this.tradeData.make = data.make;
      this.tradeData.body = data.body;
      this.tradeData.fuel = data.fuel;
      this.tradeData.model = data.model;
      this.tradeData.drivetrain = data.drivetrain;
      this.tradeData.trim = data.trim;
      this.tradeData.transmmission = data.transmmission;
      this.tradeData.vinNo = data.vinNo;
      this.tradeData.stockNumber = data.stockNumber;
      this.tradeData.seatingCapacity = data.seatingCapacity;
      this.tradeData.numberOfDoors = data.numberOfDoors;
      //MSRPConfiguration
      this.tradeData.origenalMarket = data.origenalMarket;
      this.tradeData.baseMsrp = data.baseMsrp;
      this.tradeData.msrpCurrency = data.msrpCurrency;
      if (data.get_msrp_options) {
        this.msrpOptions = data.get_msrp_options;
        this.tradeData.msrpOptions = this.msrpOptions;
      } else {
        this.tradeData.msrpOptions = data.msrpOptions;
      }
      //AccesspriesRegistration
      if (data.get_accessiore_options) {
        this.accessioreOption = data.get_accessiore_options;
        this.tradeData.accessioreOptions = this.accessioreOption;
      } else {
        this.tradeData.accessioreOptions = data.accessioreOptions;
      }
      //VehicleDeclearation
      if (data.vehicleDeclearation) {
        this.tradeData.vehicleDeclearation = data.vehicleDeclearation;
      } else {
        this.tradeData.vehicleDeclearation = [data.vd1, data.vd2, data.vd3, data.vd4, data.vd5, data.vd6, data.vd7];
      }
      this.tradeData.vehicleDeclearationText = data.vd1Text;
      //VehicleAppraisal & Lien
      this.tradeData.vehicleTitleType = data.vehicleTitleType;
      this.tradeData.appraisedVehiclePrice = data.appraisedVehiclePrice;
      this.tradeData.creditDue = data.appraisedVehiclePrice;
      this.tradeData.appraisalNotes = data.appraisalNotes;
      this.tradeData.payoutType = data.payoutType;
      this.tradeData.beforeTax = data.beforeTax;
      this.tradeData.unitlDate = data.unitlDate;
      this.tradeData.gstRate = data.gstRate;
      //VehiclePhotos
      this.tradeData.quoteGallery = data.quoteGallery;
      //LienHolder
      this.tradeData.lienCustomerId = data.lienCustomerId;
      this.tradeData.lienHolder = {
        sellerType: data.lienHolder.sellerType,
        phoneNumber: data.lienHolder.phoneNumber,
        firstName: data.lienHolder.firstName,
        lastName: data.lienHolder.lastName,
        email: data.lienHolder.email,
        unitNumber: data.lienHolder.unitNumber,
        provence: data.lienHolder.provence,
        streetAddress: data.lienHolder.streetAddress,
        city: data.lienHolder.city,
        postalCode: data.lienHolder.postalCode,
        country: data.lienHolder.country,
        gst: data.lienHolder.gst,
        pst: data.lienHolder.pst,
        dealer: data.lienHolder.dealer,
        driverLicenseNumber: data.lienHolder.driverLicenseNumber,
        otherIdNumber: data.lienHolder.otherIdNumber,
        dateOfBirth: data.lienHolder.dateOfBirth,
        customerId: data.lienHolder.customerId,
      };
    },
    getTradeInventorySelectId(id, quoteId) {
      if (id != null) {
        this.formShow = true;
        store
          .dispatch('purchase/getTradeInventorySelectId', id)
          .then((response) => {
            this.tradeData.vehicleId = id;
            this.tradeData.inventoryId = id;
            this.tradeData.condition = response.data.condition.charAt(0).toUpperCase() + response.data.condition.slice(1);
            this.tradeData.mileageUnit = response.data.mileageUnit;
            this.tradeData.extColour = response.data.extColour;
            this.tradeData.modelYear = response.data.modelYear;
            this.tradeData.mileageValue = response.data.mileageValue;
            this.tradeData.intColour = response.data.intColour;
            this.tradeData.make = response.data.make;
            this.tradeData.body = response.data.body;
            this.tradeData.fuel = response.data.fuel;
            this.tradeData.model = response.data.model;
            this.tradeData.drivetrain = response.data.drivetrain;
            this.tradeData.trim = response.data.trim;
            this.tradeData.transmmission = response.data.transmmission;
            this.tradeData.vinNo = response.data.vinNo;
            this.tradeData.stockNumber = response.data.stockNumber;
            this.tradeData.seatingCapacity = response.data.seatingCapacity;
            this.tradeData.numberOfDoors = response.data.numberOfDoors;
            //MSRPConfiguration
            this.tradeData.origenalMarket = response.data.origenalMarket;
            this.tradeData.baseMsrp = response.data.baseMsrp;
            this.tradeData.msrpCurrency = response.data.msrpCurrency;
            this.msrpOptions = response.data.get_msrp_options;
            this.tradeData.msrpOptions = this.msrpOptions;
            //AccesspriesRegistration
            this.accessioreOption = response.data.get_accessiore_options;
            this.tradeData.accessioreOptions = this.accessioreOption;
            //VehicleDeclearation
            this.tradeData.vehicleDeclearation = [response.data.vd1, response.data.vd2, response.data.vd3, response.data.vd4, response.data.vd5, response.data.vd6, response.data.vd7];
            this.tradeData.vehicleDeclearationText = response.data.vd1Text;
            setTimeout(() => (this.formShow = false), 500);
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching inventory',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        //CLEAR INVENTORY MODEL
        this.clearInventoryData();
      }
    },
    clearInventoryData() {
      //CLEAR INVENTORY MODEL
      this.tradeData.condition = null;
      this.tradeData.mileageUnit = null;
      this.tradeData.extColour = null;
      this.tradeData.modelYear = null;
      this.tradeData.mileageValue = null;
      this.tradeData.intColour = null;
      this.tradeData.make = null;
      this.tradeData.body = null;
      this.tradeData.fuel = null;
      this.tradeData.model = null;
      this.tradeData.seatingCapacity = null;
      this.tradeData.drivetrain = null;
      this.tradeData.trim = null;
      this.tradeData.numberOfDoors = null;
      this.tradeData.transmmission = null;
      this.tradeData.stockNumber = null;
      this.tradeData.vinNo = null;
      this.tradeData.origenalMarket = null;
      this.tradeData.msrpCurrency = null;
      this.tradeData.baseMsrp = null;
      this.tradeData.msrpOptions = [];
      this.tradeData.accessioreOptions = [];
      this.tradeData.vehicleDeclearation = [null, null, null, null, null, null, null];
      this.tradeData.vehicleDeclearationText = null;
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    getInventoryTradeDrop() {
      store
        .dispatch('purchase/getInventoryTradeDrop')
        .then((response) => {
          response.data.forEach((element) => {
            if (element.value != null) {
              this.dropInventorys.push(element);
            }
          });
        })
        .catch((error) => {});
    },
    getCustomerDrops() {
      store
        .dispatch('purchase/getCustomerDropDowns')
        .then((response) => {
          this.dropCustomers = response.data;
        })
        .catch((error) => {});
    },
    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-dropdown {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
    cursor: pointer;
  }
}
</style>
