<template>
  <div>
    <b-sidebar id="existing-trade-select" sidebar-class="sidebar-lg" :visible="isTaskHandlerSidebarActive" bg-variant="white" width="40%" shadow backdrop no-header right @change="(val) => $emit('update:is-task-handler-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Select an Existing Trade-in Quote</h5>
          <feather-icon icon="XIcon" class="ml-1 cursor-pointer" size="16" @click="hide()" />
        </div>

        <b-card no-body class="business-card">
          <b-card-header class="pb-1">
            <!-- <b-card-title>Existing Trade-in Quotes </b-card-title> -->
          </b-card-header>

          <b-card-body v-if="tradeAllQuoteData.length > 0">
            <div v-for="quote in tradeAllQuoteData" :key="quote.id">
              <div class="business-items mt-2">
                <div class="business-item" v-if="leaseQuoteData.purchaserIdentification.customerId == quote.purchaserId">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-form-checkbox v-model="quote.checked" :checked="quote.checked">
                      <h5>{{ quote.value }}</h5>
                    </b-form-checkbox>
                  </div>

                  <div class="d-flex align-items-center justify-content-between mt-1 ml-2">
                    <span>[ {{ Number(quote.purchaserId) }} ] {{ quote.firstName }} {{ quote.lastName }}</span>
                  </div>

                  <div class="d-flex align-items-center justify-right mt-1 ml-2">
                    <b-badge variant="success">Appraised Vehicle Price {{ formatPrice(quote.appraisedVehiclePrice ? quote.appraisedVehiclePrice : 0) }} </b-badge>

                    <b-badge variant="info" class="ml-2">Credit Due {{ formatPrice(quote.creditDue ? quote.creditDue : 0) }} </b-badge>
                  </div>
                </div>
              </div>
            </div>

            <!-- button -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-5" @click="selectTradeQuote(tradeAllQuoteData)" variant="primary" block>
              Add
            </b-button>
          </b-card-body>

          <b-card-body v-else>
            <div class="business-items mt-2 text-center ">
              <h3 class="text-danger">There are no completed quote.</h3>
            </div>

            <!-- button -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-5" @click="selectTradeQuote(tradeAllQuoteData)" variant="primary" block>
              Close
            </b-button>
          </b-card-body>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';

import { required, email, url } from '@validations';
import { quillEditor } from 'vue-quill-editor';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },

    leaseQuoteData: {
      type: Object,
      required: true,
    },

    tradeAllQuoteData: {
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  methods: {
    selectTradeQuote(val) {
      var a = 0;
      val.forEach((element) => {
        if (element.checked == true) {
          this.leaseQuoteData.winTrades.find((x) => {
            if (x.id == element.id) {
              a++;
            }
          });
        }

        if (a == 0) {
          if (element.checked == true) {
            if (element.inventoryId == this.leaseQuoteData.inventoryId) {
              this.$swal({
                title: 'This vehicle cannot be selected',
                text: 'Vehicle selected to sell cannot be selected for trade',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                ///
              });
            } else {
              this.leaseQuoteData.winTrades.push(element);
            }
          }
        }
      });

      this.$root.$emit('bv::toggle::collapse', 'existing-trade-select');
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
