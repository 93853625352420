<template>
  <validation-observer ref="vehicleRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Description of Vehicle</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <!-- :cols="leaseQuoteData.inventoryId == null ? '10' : '10'" -->
      <b-col lg="9" md="8" sm="12">
        <b-form-group label="Vehicle*" label-for="vehicle">
          <validation-provider #default="{ errors }" name="Vehicle" rules="required">
            <v-select v-model="leaseQuoteData.inventoryId" :options="dropInventorys" :reduce="(val) => val.id" :state="errors.length > 0 ? false : null" label="value" input-id="customer-data" :clearable="false"> </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col lg="1" md="2" sm="12" class="text-center" v-if="leaseQuoteData.inventoryId != null" v-show="$Can('inventory_costs')">
        <b-form-group class="mt-2" label-for="title">
          <b-button v-b-toggle.collapse v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="gradient-light" class="btn-icon">
            <feather-icon icon="InfoIcon" />
          </b-button>
        </b-form-group>
      </b-col>

      <b-col :lg="leaseQuoteData.inventoryId != null ? 2 : 3" :md="leaseQuoteData.inventoryId != null ? 3 : 4" sm="12" class="text-center" v-show="$Can('inventory_create')">
        <b-form-group class="mt-2" label-for="title">
          <b-button :to="{ name: 'inventory-add-sale' }" block variant="relief-dark" class="btn-icon">
            New Inventory
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <span v-if="leaseQuoteData.inventoryId != null">
      <!-- <b-form-group class="mt-2" label-for="title" v-show="$Can('inventory_edit')">
        <b-button :to="{ name: 'inventory-edit-purchase', params: { id: purchaseQuoteData.inventoryId, purchaseid: purchaseQuoteData.id } }" variant="relief-warning" class="btn-icon"> Edit Inventory </b-button>
      </b-form-group> -->

      <app-collapse accordion>
        <app-collapse-item title="General Information">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Stock Number:</th>
                <td>
                  <span> {{ invInfo.stockNumber ? invInfo.stockNumber : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Model Year:</th>
                <td>
                  <span>
                    {{ invInfo.get_model_year ? invInfo.get_model_year.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Make:</th>
                <td>
                  <span>
                    {{ invInfo.get_make ? invInfo.get_make.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Model:</th>
                <td>
                  <span>
                    {{ invInfo.get_model ? invInfo.get_model.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Model Variation:</th>
                <td>
                  <span>
                    {{ invInfo.modelVariation ? invInfo.modelVariation : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Mileage:</th>
                <td>
                  <span> {{ invInfo.mileageValue ? invInfo.mileageValue : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Mileage Unit:</th>
                <td>
                  <span> {{ invInfo.mileageUnit ? invInfo.mileageUnit : ' ' }} </span>
                </td>
              </tr>

              <tr>
                <th>Body Type:</th>
                <td>
                  <span> {{ invInfo.get_body ? invInfo.get_body.value : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Interior Trim:</th>
                <td>
                  <span> {{ invInfo.interiorTrim ? invInfo.interiorTrim : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Chassis Code:</th>
                <td>
                  <span> {{ invInfo.chassisCode ? invInfo.chassisCode : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Doors:</th>
                <td>
                  <span> {{ invInfo.seatingCapacity ? invInfo.seatingCapacity : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Seats:</th>
                <td>
                  <span> {{ invInfo.numberOfDoors ? invInfo.numberOfDoors : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>External Colour</th>
                <td>
                  <span> {{ invInfo.get_ext_colour ? invInfo.get_ext_colour.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Internal Colour:</th>
                <td>
                  <span> {{ invInfo.get_int_colour ? invInfo.get_int_colour.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Listing Price:</th>
                <td>
                  <span> {{ invInfo.listingPrice ? invInfo.listingPrice : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Engine Technical Data">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Engine Code:</th>
                <td>
                  <span> {{ invInfo.engineCode ? invInfo.engineCode : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Number of Cylinders :</th>
                <td>
                  <span> {{ invInfo.numberOfCylinders ? invInfo.numberOfCylinders : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Engine Alignment :</th>
                <td>
                  <span> {{ invInfo.engineAlignment ? invInfo.engineAlignment : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Engine Position :</th>
                <td>
                  <span> {{ invInfo.enginePosition ? invInfo.enginePosition : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Engine Displacement :</th>
                <td>
                  <span> {{ invInfo.engineDisplacement ? invInfo.engineDisplacement : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel Type :</th>
                <td>
                  <span> {{ invInfo.get_fuel ? invInfo.get_fuel.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel Tank Capacity:</th>
                <td>
                  <span> {{ invInfo.fuelTankCapacity ? invInfo.fuelTankCapacity : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel System:</th>
                <td>
                  <span> {{ invInfo.fuelSystem ? invInfo.fuelSystem : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Bore x Stroke:</th>
                <td>
                  <span> {{ invInfo.boreStroke ? invInfo.boreStroke : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Number of Valves:</th>
                <td>
                  <span> {{ invInfo.numberOfValves ? invInfo.numberOfValves : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Aspiration:</th>
                <td>
                  <span> {{ invInfo.aspiration ? invInfo.aspiration : '' }} </span>
                </td>
              </tr>
              <tr>
                <th>Compression Ratio:</th>
                <td>
                  <span> {{ invInfo.compressionRatio ? invInfo.compressionRatio : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Maximum Horsepower :</th>
                <td>
                  <span> {{ invInfo.maximumHorsepower ? invInfo.maximumHorsepower : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Maximum Torque :</th>
                <td>
                  <span> {{ invInfo.maximumTorque ? invInfo.maximumTorque : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Drivetrain, brakes and suspension">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Drivetrain</th>
                <td>
                  <span> {{ invInfo.get_drive_train ? invInfo.get_drive_train.value : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Transmission :</th>
                <td>
                  <span> {{ invInfo.get_transmmission ? invInfo.get_transmmission.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Suspension:</th>
                <td>
                  <span> {{ invInfo.frontSuspension ? invInfo.frontSuspension : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Suspension :</th>
                <td>
                  <span> {{ invInfo.rearSuspension ? invInfo.rearSuspension : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Chassis Type :</th>
                <td>
                  <span> {{ invInfo.chassisType ? invInfo.chassisType : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Chassis Materials :</th>
                <td>
                  <span> {{ invInfo.chassisMaterials ? invInfo.chassisMaterials : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Brakes - Disc Dimensions:</th>
                <td>
                  <span> {{ invInfo.frontBrakes ? invInfo.frontBrakes : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Brakes - Disc Dimensions:</th>
                <td>
                  <span> {{ invInfo.rearBrakes ? invInfo.rearBrakes : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Tyres - Rims Dimensions:</th>
                <td>
                  <span> {{ invInfo.frontTyres ? invInfo.frontTyres : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Rear Tyres - Rims Dimensions s:</th>
                <td>
                  <span> {{ invInfo.rearTyres ? invInfo.rearTyres : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Size and Dimensions">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Length :</th>
                <td>
                  <span> {{ invInfo.length ? invInfo.length : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Width :</th>
                <td>
                  <span> {{ invInfo.width ? invInfo.width : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Height :</th>
                <td>
                  <span> {{ invInfo.height ? invInfo.height : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Wheelbase :</th>
                <td>
                  <span> {{ invInfo.wheelbase ? invInfo.wheelbase : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Front Axle Width :</th>
                <td>
                  <span> {{ invInfo.frontAxleWidth ? invInfo.frontAxleWidth : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Axle Width :</th>
                <td>
                  <span> {{ invInfo.rearAxleWidth ? invInfo.rearAxleWidth : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Curb Weight :</th>
                <td>
                  <span> {{ invInfo.curbWeight ? invInfo.curbWeight : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
      </app-collapse>
    </span>

    <b-collapse id="collapse">
      <b-card>
        <table class="table table-striped table-hover" style="width:100%;">
          <thead class="thead-dark">
            <tr>
              <th colspan="3">Order Id</th>
              <th colspan="3">Type</th>
              <th colspan="3">Amount</th>
              <th colspan="3">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="invInfo.listingPrice != 0">
              <td colspan="3"><strong> Listing Price: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.listingPrice, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-for="income in invInfo.incomes" :key="income.id">
              <td colspan="3">{{ income.orderId }}</td>
              <td colspan="3">Sale Order</td>
              <td colspan="3">{{ formatPrice(income.price, 2) }}</td>
              <td colspan="3">{{ income.title }}</td>
            </tr>

            <tr v-for="packDown in invInfo.adjustmentDown" :key="packDown.id">
              <td colspan="3">{{ packDown.orderId }}</td>
              <td colspan="3">Adjustment</td>
              <td colspan="3">{{ formatPrice(packDown.price, 2) }}</td>
              <td colspan="3">Pack Down</td>
            </tr>

            <tr v-if="invInfo.vehicleRevenueTotal != 0">
              <td colspan="3"><strong> Revenue Subtotal: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.vehicleRevenueTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-if="invInfo.purchasePrice > 0">
              <td colspan="3">Quote #{{ invInfo.purchaseQuoteId }}</td>
              <td colspan="3">Purchase Price</td>
              <td colspan="3">{{ formatPrice(invInfo.purchasePrice, 2) }}</td>
              <td colspan="3">Purchase Quote</td>
            </tr>

            <tr v-for="expense in invInfo.expenses" :key="expense.id">
              <td colspan="3">{{ expense.orderId }}</td>
              <td colspan="3">Purchase Order</td>
              <td colspan="3">{{ formatPrice(expense.price, 2) }}</td>
              <td colspan="3">{{ expense.title }}</td>
            </tr>

            <tr v-for="packUp in invInfo.adjustmentUp" :key="packUp.id">
              <td colspan="3">{{ packUp.orderId }}</td>
              <td colspan="3">Adjustment</td>
              <td colspan="3">{{ formatPrice(packUp.price, 2) }}</td>
              <td colspan="3">Pack Up</td>
            </tr>

            <tr v-if="invInfo.pack > 0">
              <td colspan="3"></td>
              <td colspan="3">Pack</td>
              <td colspan="3">{{ formatPrice(invInfo.pack, 2) }}</td>
              <td colspan="3">Pack</td>
            </tr>

            <tr v-if="invInfo.vehicleCostTotal != 0">
              <td colspan="3"><strong> Expense Subtotal: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.vehicleCostTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-if="invInfo.listingPrice > 0">
              <td colspan="3"><strong> Marginal Profit: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.listingPrice + invInfo.vehicleRevenueTotal - invInfo.vehicleCostTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>

    <!-- :before-change="validationForm1" -->
  </validation-observer>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import { required, email } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import router from '@/router';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    required,

    vSelect,
    VueNumericInput,
    ToastificationContent,

    //validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    dropInventorys: {
      type: Array,
      required: true,
    },
    leaseQuoteData: {
      type: Object,
      required: true,
    },
    invInfo: {
      type: Object,
      required: false,
    },
  },

  data() {
    var dropCondition = ['NEW', 'USED'];
    var dropMileageUnit = ['KM', 'MI'];
    var wherefrom = router.currentRoute.name;

    return {
      wherefrom,
      dropdownLoading: false,
      dropCondition,
      dropMileageUnit,
      modelOptionsVal: [],
      trimOptionsVal: [],
    };
  },

  watch: {
    'leaseQuoteData.make': {
      handler: function(id, before) {
        this.makeSelected(this.leaseQuoteData.make);
      },
    },

    'leaseQuoteData.model': {
      handler: function(id, before) {
        if (this.leaseQuoteData.model != null) {
          //  this.msrpClick();
          this.modelSelected(this.leaseQuoteData.model);
        }
      },
    },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    costTitle(data) {
      return data.substring(0, 3) + 'T' + data.substring(3, 5) + 'S' + data.substring(5, 7) + 'O' + data.substring(7, 10) + 'C';
    },
  },
};
</script>
